import * as React from "react";
import Layout from "../components/layout/Layout";
import Contact from "../components/global/Contact";

const ContactPage = () => {
  return (
    <Layout pageTitle="Kontakt">
      <Contact addPadding={false} />
    </Layout>
  );
};

export default ContactPage;
